import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 全局注册基础组件
import '@/components/base'
// 引入样式
import '@/styles/index.scss'
// element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
/* import {
  Button,
  ButtonGroup,
  Select,
  Row,
  Col,
  Card,
  Input,
  Switch,
  Tooltip,
  Slider,
  Autocomplete,
  Dialog,
  Popconfirm,
  Collapse,
  CollapseItem,
  Checkbox,
  CheckboxGroup,
  Option,
  OptionGroup,
  Message,
  MessageBox
} from 'element-ui' */

Vue.config.productionTip = false
/* Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message */

Vue.use(ElementUI)
/* Vue.use(ElementUI)
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(Input)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Slider)
Vue.use(Autocomplete)
Vue.use(Dialog)
Vue.use(ButtonGroup)
Vue.use(Popconfirm)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup) */

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
