import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    children: [
      {
        path: 'keyword-filter',
        name: 'keyword-filter',
        component: () => import('@/views/keyword-filter/index.vue')
      },
      {
        path: 'keyword-diff',
        name: 'keyword-diff',
        component: () => import('@/views/keyword-diff')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
