<template>
  <div>
    <Navbar />
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import Navbar from './Navbar'

export default {
  name: 'layout',
  components: {
    Navbar
  }
}
</script>

<style lang="scss" scoped></style>
