import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import keywordFilter from './module/keyword-filter'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters,
  modules: {
    keywordFilter
  }
})
