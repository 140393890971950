import inputString from '../../views/keyword-filter/keywords'

const USER_CONFIG = {
  kwRepeat: true,
  kwLength: 25,
  kwInclude: [],
  kwIncludeAnd: [],
  kwExclude: []
}

localStorage.setItem(
  'kwCateList',
  JSON.stringify([
    {
      title: '通用',
      kws: ['手机壳', '卡通', '潮流', '可爱'],
      checkList: []
    },
    {
      title: '苹果',
      kws: ['se2', 'iphone11', '11pro', 'iphone12', '12pro', '12promax'],
      checkList: []
    },
    {
      title: '华为',
      kws: ['p30', 'p40', 'mate30', 'mate40', 'nova7', 'nova8'],
      checkList: []
    },
    {
      title: 'oppo',
      kws: ['reno4', 'reno5'],
      checkList: []
    }
  ])
)

const state = {
  // 表单配置
  kwRepeat: USER_CONFIG.kwRepeat,
  kwLength: USER_CONFIG.kwLength,
  kwInclude: ['苹果', '手机壳'],
  kwIncludeAnd: [],
  kwExclude: ['华为'],
  // 输入列表
  inputString: inputString,
  // 筛选条件：all 代表所有
  filterType: ''
}

const mutations = {
  UPDATE_KW_LENGTH(state, length) {
    state.kwLength = length
  },
  UPDATE_KW_REPEAT(state, flag) {
    state.kwRepeat = flag
  },
  UPDATE_INPUT_STRING(state, str) {
    state.inputString = str
  },
  UPDATE_FILTER_TYPE(state, type) {
    state.filterType = type
  },
  RESET_FILTER(state) {
    state.kwRepeat = USER_CONFIG.kwRepeat
    state.kwLength = USER_CONFIG.kwLength
    state.kwExclude = []
    state.kwInclude = []
    state.kwIncludeAnd = []
  },
  // kwInclude
  CLEAR_KW_INCLUDE(state) {
    state.kwInclude = []
  },
  UPDATE_KW_INCLUDE(state, arr) {
    state.kwInclude = arr
  },
  ADD_KW_INCLUDE_ITEM(state, arr) {
    const res = new Set(state.kwInclude.concat(arr))
    state.kwInclude = [...res]
  },
  REMOVE_KW_INCLUDE_ITEM(state, index) {
    state.kwInclude.splice(index, 1)
  },
  // kwIncludeAnd
  CLEAR_KW_INCLUDE_AND(state) {
    state.kwIncludeAnd = []
  },
  UPDATE_KW_INCLUDE_AND(state, arr) {
    state.kwIncludeAnd = arr
  },
  ADD_KW_INCLUDE_AND_ITEM(state, arr) {
    const res = new Set(state.kwIncludeAnd.concat(arr))
    state.kwIncludeAnd = [...res]
  },
  REMOVE_KW_INCLUDE_AND_ITEM(state, index) {
    state.kwIncludeAnd.splice(index, 1)
  },
  // kwExclude
  CLEAR_KW_EXCLUDE(state) {
    state.kwExclude = []
  },
  UPDATE_KW_EXCLUDE(state, arr) {
    state.kwExclude = arr
  },
  ADD_KW_EXCLUDE_ITEM(state, arr) {
    const res = new Set(state.kwExclude.concat(arr))
    state.kwExclude = [...res]
  },
  REMOVE_KW_EXCLUDE_ITEM(state, index) {
    state.kwExclude.splice(index, 1)
  }
}

const actions = {
  updateKwLength({ commit }, length) {
    commit('UPDATE_KW_LENGTH', length)
  },
  updateKwRepeat({ commit }, flag) {
    commit('UPDATE_KW_REPEAT', flag)
  },
  updateInputString({ commit }, str) {
    commit('UPDATE_INPUT_STRING', str)
  },
  updateFilterType({ commit }, str) {
    commit('UPDATE_FILTER_TYPE', str)
  },
  // 恢复初始化
  resetFilter({ commit }) {
    commit('RESET_FILTER')
  },
  // kwInclude
  clearKwInclude({ commit }) {
    commit('CLEAR_KW_INCLUDE')
  },
  updateKwInclude({ commit }, arr) {
    commit('UPDATE_KW_INCLUDE', arr)
  },
  addKwIncludeItem({ commit }, arr) {
    commit('ADD_KW_INCLUDE_ITEM', arr)
  },
  removeKwIncludeItem({ commit }, index) {
    commit('REMOVE_KW_INCLUDE_ITEM', index)
  },
  // kwIncludeAnd
  clearKwIncludeAnd({ commit }) {
    commit('CLEAR_KW_INCLUDE_AND')
  },
  updateKwIncludeAnd({ commit }, arr) {
    commit('UPDATE_KW_INCLUDE_AND', arr)
  },
  addKwIncludeAndItem({ commit }, arr) {
    commit('ADD_KW_INCLUDE_AND_ITEM', arr)
  },
  removeKwIncludeAndItem({ commit }, index) {
    commit('REMOVE_KW_INCLUDE_AND_ITEM', index)
  },
  // kwExclude
  clearKwExclude({ commit }) {
    commit('CLEAR_KW_EXCLUDE')
  },
  updateKwExclude({ commit }, arr) {
    commit('UPDATE_KW_EXCLUDE', arr)
  },
  addKwExcludeItem({ commit }, arr) {
    commit('ADD_KW_EXCLUDE_ITEM', arr)
  },
  removeKwExcludeItem({ commit }, index) {
    commit('REMOVE_KW_EXCLUDE_ITEM', index)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
