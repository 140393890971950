<template>
  <span :class="['b-badge', type]">
    <slot></slot>
    <span v-show="!closable" class="b-badge__close" @click="$emit('delete')">
      <i class="el-icon-close"></i>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    type: { type: String, default: 'primary' },
    closable: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.b-badge {
  position: relative;
  box-sizing: border-box;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  border-radius: 3px;

  @each $type, $color in ('primary', #409eff), ('success', #67c23a),
    ('info', #909399), ('warning', #e6a23c), ('danger', #f56c6c)
  {
    &.#{$type} {
      background-color: $color;
    }
  }

  &__close {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 50%;
  }
}
</style>
