<template>
  <div class="navbar">
    <router-link
      :class="['navbar-item', { active: $route.path === item.path }]"
      :to="item.path"
      tag="div"
      v-for="item of list"
      :key="item.path"
    >
      {{ item.title }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'navbar',
  data() {
    return {
      list: [
        {
          title: '筛选词',
          path: '/keyword-filter'
        },
        {
          title: '列表对比',
          path: '/keyword-diff'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  box-sizing: border-box;
  /* display: inline-flex; */
  padding: 0 0.2rem;
  height: 0.5rem;
  line-height: 0.5rem;
  color: #fff;
  background-color: #409eff;
  &-item {
    float: left;
    box-sizing: border-box;
    padding: 0 0.2rem;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background-color: #f56c6c;
    }
    &.active {
      background-color: #f56c6c;
    }
  }
}
</style>
